/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @next/next/no-html-link-for-pages */
import styles from "../styles/Home.module.css";
import { Link, Box } from "@chakra-ui/react";
import IndexLayout from "../components/index_layout";

import React, { useState, useEffect } from "react";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { RepeatSwiper } from "../components/main_swiper";
import { GetCookie } from "../provider/common";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);
const axios = require("axios").default;

export default function Home() {
  const [list, setList] = useState("best");
  const [newProduct, setNewProduct] = useState("");

  const [bestProduct, setBestProduct] = useState("");
  const [specialProduct, setSpecialProduct] = useState("");
  const [hotDealProduct, setSHotDealProduct] = useState("");
  const [shareProduct, setShareProduct] = useState("");
  const [customSwiper, setCustomSwiper] = useState();
  const [isLoginCheck, setIsLoginCheck] = useState("");

  const clickToggle = async (e) => {
    // 토글을 누를때마다 앞으로 초기화
    customSwiper.slideTo(0, 500);
    setList(e.target.value);
  };

  const Data = async () => {
    // 신상품 시작
    const newProductURL0 =
      process.env.PANDA_API_URL +
      "/api/mallshop/getCategoryWithProduct?ca_id=16";
    const newProductRes0 = await axios.get(newProductURL0, {
      headers: {
        "content-type": "application/json",
      },
    });
    setNewProduct(newProductRes0.data.data);

    // 신상품 끝

    // 베스트 시작
    const bestProductURL0 =
      process.env.PANDA_API_URL +
      "/api/mallshop/getCategoryWithProduct?ca_id=14";
    const bestProductRes0 = await axios.get(bestProductURL0, {
      headers: {
        "content-type": "application/json",
      },
    });
    setBestProduct(bestProductRes0.data.data);
    // 베스트 끝

    // 특가 시작
    const specialProductURL0 =
      process.env.PANDA_API_URL +
      "/api/mallshop/getCategoryWithProduct?ca_id=15";
    const specialProductRes0 = await axios.get(specialProductURL0, {
      headers: {
        "content-type": "application/json",
      },
    });

    setSpecialProduct(specialProductRes0.data.data);
    // 특가 끝

    // 핫딜 부품 시작
    const hotdealProductURL0 =
      process.env.PANDA_API_URL +
      "/api/mallshop/getCategoryWithProductPrice?ca_id=11";
    const hotdealProductRes0 = await axios.get(hotdealProductURL0, {
      headers: {
        "content-type": "application/json",
      },
    });
    setSHotDealProduct(hotdealProductRes0.data.data);
    // 핫딜 부품 끝
  };

  const BannerData = [
    {
      link: "/seller/event",
      img: "url(./images/banner/banner_20.png)",
    },
    {
      link: "/parts/mallcategory/3201",
      img: "url(./images/banner/banner_01.png)",
    },
    {
      link: "/parts/mallcategory/3200?categorykeyword=Raspberry%20Pi%205",
      img: "url(./images/banner/banner_18.png)",
    },
    {
      link: "/parts/mallview/1574027665",
      img: "url(./images/banner/banner_04.png)",
    },
    {
      link: "/parts/mallcategory/11",
      img: "url(./images/banner/banner_5.png)",
    },
    { link: "/policy/company", img: "url(./images/banner/banner_10.png)" },
  ];

  //  브랜드 로고
  const brandData = [
    {
      name: "Raspberry Pi",
      img: "./images/brand/01.raspberry_pi.png",
      link: "/parts/mallcategory/3200",
    },
    {
      name: "Arduino",
      img: "./images/brand/02.arduino.png",
      link: "/parts/mallcategory/3201",
    },
    {
      name: "DF Robot",
      img: "./images/brand/03.dfrobot.png",
      link: "/parts/mallcategory/3209",
    },
    {
      name: "Sparkfun",
      img: "./images/brand/04.sparkfun.jpeg",
      link: "/parts/mallcategory/3202",
    },
    {
      name: "NVDIA",
      img: "./images/brand/05.nvdia.png",
      link: "/parts/mallcategory/3203",
    },
    {
      name: "Robotshop",
      img: "./images/brand/10.robotshop.png",
      link: "/parts/mallcategory/3208",
    },
    {
      name: "Seeed Studio",
      img: "./images/brand/06.seed_studio.png",
      link: "/parts/mallcategory/3204",
    },
    {
      name: "Adafruit",
      img: "./images/brand/08.adafruit.png",
      link: "/parts/mallcategory/3206",
    },

    {
      name: "Particle",
      img: "./images/brand/07.particle.png",
      link: "/parts/mallcategory/3205",
    },
    {
      name: "Espressif",
      img: "./images/brand/09.espressif_.png",
      link: "/parts/mallcategory/3207",
    },
    {
      name: "Texas Instruments",
      img: "./images/brand/13.texas_instruments.png",
      link: "/parts/mallcategory/3210",
    },
    {
      name: "Infineon",
      img: "./images/brand/14.infineon.png",
      link: "/parts/mallcategory/3211",
    },
  ];

  // 이벤트/기획전 데이터
  const couponData1 = [
    {
      title: "신규회원 가입시 3%할인 쿠폰 제공",
      img: "../images/main.png",
      date: "23.11.28",
      link: "/policy/event",
    },
  ];
  // global distributor 로고
  const distributorData = [
    { name: "Arrow", img: "./images/distributor/01.arrow.png", link: "#" },
    { name: "Digikey", img: "./images/brand/dk_real_logo.png", link: "#" },
    { name: "Mouser", img: "./images/distributor/03.mouser.png", link: "#" },
    {
      name: "Element",
      img: "./images/distributor/04.element14.png",
      link: "#",
    },
    { name: "Vertical", img: "./images/distributor/05.verical.png", link: "#" },
    { name: "OneChip", img: "./images/distributor/06.onechip.png", link: "#" },
    { name: "future", img: "./images/distributor/09.futrue.png", link: "#" },
    { name: "tti", img: "./images/distributor/10.tti.png", link: "#" },
    { name: "Rochester Electronics", img: "./images/brand/11.Rochester.png" },
    { name: "Pandaparts alliance", img: "./images/brand/12.alliance.png" },
  ];

  // scroll behavior
  const onChangePage = async (e) => {
    customSwiper.slideTo(0, 500);
    setList(e.target.value);

    // 화면 사이즈를 가져온다.
    const screenWidth = window.innerWidth;

    let swiperCenter = 1000;
    // screentWidth 780 이하 인 경우
    if (screenWidth <= 780) {
      swiperCenter = swiperCenter + 600;
    }

    window.scrollTo({
      top: swiperCenter,
      behavior: "smooth",
    });
  };

  const handleLoginCheck = async () => {
    const isLogin = await GetCookie("isLogin");
    setIsLoginCheck(isLogin);
  };

  useEffect(() => {
    Data();
    handleLoginCheck();
  }, []);

  return (
    <IndexLayout>
      <Box className={styles.main_slide}>
        <Box className={styles.main_banner}>
          <Box className={styles.main_banner_lt}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              effect="fade"
              navigation={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              loopAdditionalSlides={5}
              modules={[EffectFade]}
            >
              {BannerData.length > 0
                ? BannerData.map((datum) => (
                    <SwiperSlide
                      width="1000px"
                      height="auto"
                      className={styles.pd_swiper}
                    >
                      <Link href={datum.link}>
                        <Box
                          className={styles.main_slidebox}
                          style={{
                            backgroundImage: datum.img,
                          }}
                        ></Box>
                      </Link>
                    </SwiperSlide>
                  ))
                : ""}
            </Swiper>
          </Box>

          <Box className={styles.main_banner_rt}>
            <Box className={styles.main_banner_up}>
              <Box
                className={styles.main_banner_square}
                style={{
                  backgroundImage: "url(./images/banner/banner_product_01.png)",
                }}
              >
                <Link href="/search/lists?keyword=XCZU4EG-1SFVC784I">
                  <Box className={styles.main_banner_square_ex}>
                    <Box>
                      {" "}
                      <span></span>
                      <span>
                        {" "}
                        #마켓플레이스
                        <br /> #Xilinx / AMD
                        <br /> #Zynq UltraScale+ MPSoC
                      </span>
                      <span>
                        <i className="xi-search"></i>
                      </span>
                    </Box>
                    <Box></Box>
                  </Box>
                </Link>
              </Box>

              <Box
                className={styles.main_banner_square}
                style={{
                  backgroundImage: "url(./images/banner/banner_product_02.png)",
                }}
              >
                <Link href="/search/lists?keyword=MA4AGSW2">
                  <Box className={styles.main_banner_square_ex}>
                    <Box>
                      {" "}
                      <span></span>
                      <span>
                        {" "}
                        #마켓플레이스
                        <br /> #MACOM <br />
                        #Aerospace & Defense
                      </span>
                      <span>
                        <i className="xi-search"></i>
                      </span>
                    </Box>
                    <Box></Box>
                  </Box>
                </Link>
              </Box>
              <Box
                className={styles.main_banner_square}
                style={{
                  backgroundImage: "url(./images/banner/banner_product_03.png)",
                }}
              >
                <Link href="/search/lists?keyword=70V25L25PFGI">
                  <Box className={styles.main_banner_square_ex}>
                    <Box>
                      {" "}
                      <span></span>
                      <span>
                        {" "}
                        #마켓플레이스 #마켓플레이스 <br />
                        #RENESAS <br /> #Dual-Port Static RAM{" "}
                      </span>
                      <span>
                        <i className="xi-search"></i>
                      </span>
                    </Box>
                    <Box></Box>
                  </Box>
                </Link>
              </Box>
              <Box
                className={styles.main_banner_square}
                style={{
                  backgroundImage: "url(./images/banner/banner_product_04.png)",
                }}
              >
                <Link href="/search/lists?keyword=STM32H723VGT6">
                  <Box className={styles.main_banner_square_ex}>
                    <Box>
                      {" "}
                      <span></span>
                      <span>
                        {" "}
                        #마켓플레이스
                        <br />
                        #STMicroelectronics <br /> #ARM Microcontroller
                      </span>
                      <span>
                        <i className="xi-search"></i>
                      </span>
                    </Box>
                    <Box></Box>
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box className={styles.main_banner_down}>
              <Link href="/parts/mallview/1574027666">
                <Box className={styles.main_banner_square_ex}>
                  <Box>
                    <span></span>
                    <span>
                      {" "}
                      #AI 개발보드 <br /> #딥러닝 개발보드 <br /> #엔비디아
                      젯슨나노
                    </span>
                    <span>
                      <i className="xi-search"></i>
                    </span>
                  </Box>
                  <Box></Box>
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={styles.main_banner_menu}>
          <Box className={styles.main_banner_center}>
            <button value="best" onClick={onChangePage}>
              베스트
            </button>
            <button value="special" onClick={onChangePage}>
              특가
            </button>
            <button value="new" onClick={onChangePage}>
              신상품
            </button>
            <button value="hotDeal" onClick={onChangePage}>
              핫딜
            </button>
            <button value="coupon" onClick={onChangePage}>
              이벤트/기획전
            </button>
          </Box>
        </Box>
      </Box>
      <Box className={styles.main_logo}>
        <Swiper
          navigation={true}
          className={styles.main_new_distributor_swiper}
          breakpoints={{
            1024: {
              spaceBetween: 5,
              slidesPerView: 7.5,
            },
            768: {
              spaceBetween: 5,
              slidesPerView: 5.5,
            },
            480: {
              spaceBetween: 5,
              slidesPerView: 3.5,
            },
            370: {
              spaceBetween: 5,
              slidesPerView: 2.5,
            },
          }}
        >
          {brandData.length > 0
            ? brandData.map((datum) => (
                <SwiperSlide
                  className={styles.main_new_distributor_swiper_slide}
                >
                  {" "}
                  <Box className={styles.swiper_slide_div}>
                    <Link href={datum.link}>
                      <Box
                        className={styles.pd_detail_img}
                        style={{ backgroundImage: `url(${datum.img})` }}
                      >
                        {" "}
                        <span className={styles.brand_name}>{datum.name}</span>
                      </Box>
                    </Link>
                  </Box>
                </SwiperSlide>
              ))
            : ""}
        </Swiper>
      </Box>

      <Box className={styles.main_new_product}>
        <Box className={styles.main_new_product_detail}>
          <Box className={styles.main_new_product_bx}>
            {list === "best" ? (
              <button
                value="best"
                onClick={clickToggle}
                className={styles.new_pd_menu_active}
              >
                베스트
              </button>
            ) : (
              <button
                value="best"
                onClick={clickToggle}
                className="new_pd_menu"
              >
                베스트
              </button>
            )}

            {list === "special" ? (
              <button
                value="special"
                onClick={clickToggle}
                className={styles.new_pd_menu_active}
              >
                특가
              </button>
            ) : (
              <button
                value="special"
                onClick={clickToggle}
                className="new_pd_menu"
              >
                특가{" "}
              </button>
            )}

            {list === "new" ? (
              <button
                value="new"
                onClick={clickToggle}
                className={styles.new_pd_menu_active}
              >
                신상품
              </button>
            ) : (
              <button value="new" onClick={clickToggle} className="new_pd_menu">
                신상품
              </button>
            )}

            {list === "hotDeal" ? (
              <button
                value="hotDeal"
                onClick={clickToggle}
                className={styles.new_pd_menu_active}
              >
                핫딜
              </button>
            ) : (
              <button
                value="hotDeal"
                onClick={clickToggle}
                className="new_pd_menu"
              >
                핫딜
              </button>
            )}
            {list === "coupon" ? (
              <button
                value="coupon"
                onClick={clickToggle}
                className={styles.new_pd_menu_active}
              >
                이벤트/기획전
              </button>
            ) : (
              <button
                value="coupon"
                onClick={clickToggle}
                className="new_pd_menu"
              >
                이벤트/기획전
              </button>
            )}
          </Box>
          {list === "new" ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              className={styles.main_new_product_swiper}
            >
              {/* 신상품 시작 */}
              {RepeatSwiper(newProduct, isLoginCheck)}
              {/* 신상품 끝 */}
            </Swiper>
          ) : list === "best" ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              className={styles.main_new_product_swiper}
              onSwiper={(swiper) => {
                setCustomSwiper(swiper);
              }}
            >
              {/* 베스트 상품 시작 */}
              {RepeatSwiper(bestProduct, isLoginCheck)}
              {/* 베스트 상품 끝 */}
            </Swiper>
          ) : list === "special" ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              className={styles.main_new_product_swiper}
            >
              {/* 특가 상품 시작 */}
              {RepeatSwiper(specialProduct, isLoginCheck)}
              {/* 특가 상품 끝 */}
            </Swiper>
          ) : list === "hotDeal" ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              className={styles.main_new_product_swiper}
            >
              {/* 특가 상품 시작 */}
              {RepeatSwiper(hotDealProduct, isLoginCheck)}
              {/* 특가 상품 끝 */}
            </Swiper>
          ) : list === "coupon" ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              className={styles.main_new_product_swiper}
            >
              {RepeatSwiper(couponData1, isLoginCheck)}
            </Swiper>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <Box className={styles.main_ad}>
        <Box className={styles.main_ad_detail}>
          <Box className={styles.main_ad_detail_img}>
            <img src="../images/parts_03.png" width="100%" height="auto" />
          </Box>
          <Box className={styles.main_ad_detail_ex}>
            <span>5만원 이상 구매시 무료배송 </span>
            <span>& 핫딜부품 구매시 당일배송</span>
          </Box>
          <Box className={styles.main_ad_detail_img}>
            <img src="../images/parts_04.png" width="100%" height="auto" />
          </Box>
        </Box>
      </Box>

      <Box className={styles.main_distributor}>
        <h3>
          <span>전자부품 글로벌 채널</span>
        </h3>

        <Box className={styles.main_distributor_detail}>
          <Swiper
            breakpoints={{
              1024: {
                spaceBetween: 5,
                slidesPerView: 7.5,
              },
              768: {
                spaceBetween: 5,
                slidesPerView: 5.5,
              },
              480: {
                spaceBetween: 5,
                slidesPerView: 3.5,
              },
              370: {
                spaceBetween: 5,
                slidesPerView: 2.5,
              },
            }}
            navigation={true}
            className={styles.main_new_distributor_swiper}
          >
            {distributorData.length > 0
              ? distributorData.map((datum) => (
                  <SwiperSlide
                    className={styles.main_new_distributor_swiper_slide}
                  >
                    {" "}
                    <Box className={styles.swiper_slide_div}>
                      <Box
                        className={styles.pd_detail_img}
                        style={{ backgroundImage: `url(${datum.img})` }}
                      ></Box>
                    </Box>
                  </SwiperSlide>
                ))
              : ""}
          </Swiper>
        </Box>
      </Box>
    </IndexLayout>
  );
}
